import clsx from 'clsx'
import React, { CSSProperties, FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { ConnectedPlatform } from 'src/interfaces/Platform.interface'
import { KTSVG } from 'src/marketConnect/helpers'

type ConnectedPlatformStripe = {
    platform: ConnectedPlatform
    onDelete: () => void
    openSettingsModal: () => void
    openPlatformdata: () => void
}

const ConnectPlatformStrip: FC<ConnectedPlatformStripe> = ({ platform, onDelete, openSettingsModal, openPlatformdata }) => {

    const menuStyle:CSSProperties =  {zIndex:105, position:"absolute", inset:"0px 0px auto auto", margin:"0px", transform:"translate3d(-20px, 60px, 0px)" }

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <div className='col-12' >
        <div className='p-2 card shadow my-2' >
            <div className='p-2 d-flex flex-column justify-content-between border border-light-primary rounded' >
                <div className='d-flex justify-content-between ' >
                <div className='align-self-center my-2 p-2' >
                    <h4 className='title mb-0' >{platform.display_name}</h4>
                </div>
                <div className='d-inline-flex align-items-baseline ' >
                    <div className='d-none d-md-inline-flex align-items-center justify-content-end ' >
                    
                    <button type="button" onClick={()=>setMenuOpen(!menuOpen)} className="btn p-2" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                    <KTSVG path="media/icons/duotune/general/gen023.svg" className="svg-icon-muted svg-icon-2hx" />
                    </button>
                    <div
                        className={clsx("menu menu-sub menu-sub-dropdown w-100px w-md-200px",{
                            'show' : menuOpen == true
                            } )}
                        data-kt-menu="true"
                        key={menuOpen+''}
                        data-popper-placement="start"
                        style={menuOpen ? menuStyle : {} }
                        >
                         
                        <div className="px-7 py-5">
                            <div className='menu-item' >
                                <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/${platform.id}`} >
                                    View Order
                                </Link>
                            </div>
                            <div className='separator my-2' ></div>
                            <div className='menu-item' >
                                <Link className='menu-link fs-5 bg-light fw-bold text-info ' to={`/inventories/${platform.id}`} >
                                    View Inventory
                                </Link>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    <button className='btn p-2' onClick={openSettingsModal} >
                        <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-2hx" />
                    </button>
                    <button className='btn p-2 btn-active-color-danger btn-icon' onClick={onDelete} >
                        <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" />
                    </button>
                </div>
                </div>
                <div className='d-md-none d-inline-flex align-items-center justify-content-end ' >
                            <Link className='btn btn-sm btn-info' to={`/orders/${platform.id}`} >
                                View Order
                            </Link>
                            <Link className='btn btn-sm btn-info ms-4' to={`/inventories/${platform.id}`} >
                                View Inventory
                            </Link>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ConnectPlatformStrip