import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosClient from "../AxiosClient";


export const createConnectionWithAIModelAsync = createAsyncThunk(
    "gptModels/createConnectionWithAIModel",
    async function (payload: {
        model_name: string,
        token: string,
    }, toolkit) {
        return await AxiosClient("POST", "api/user-settings/", payload, toolkit, [])
    }
);

export const fetchConnectedAgentAsync = createAsyncThunk(
    "gptModels/fetchConnectedAgent",
    async function (payload, toolkit) {
        const url = `api/user-settings/`
        return await AxiosClient("GET", url, [], toolkit, [])
    }
);

export const fetchAvailableGPTModelsAsync = createAsyncThunk(
    "gptModels/fetchAvailableGPTModel",
    async function (payload, toolkit) {
        const url = `api/available-models/`
        return await AxiosClient("GET", url, [], toolkit, [])
    }
);

export const deleteAgentAsync = createAsyncThunk(
    "gptModels/deleteAgent",
    async function (payload: { id: number }, toolkit) {
      const url = `api/user-settings/${payload.id}`
      return await AxiosClient("DELETE", url, [], toolkit, [])
    }
  
  );