
import { useEffect, useState } from 'react';
import AlertModal from 'src/utils/modals/AlertModal';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { toast } from 'react-toastify';
import AddNewAgent from './components/AddNewAgent';
import AvailableGPTModels from './components/AvailableGPTModels';
import { fetchConnectedAgentAsync, deleteAgentAsync } from 'src/redux/actions/gptModelsActions';
import ConnectAgentStrip from './components/ConnectAgentStrip';
import Loader from 'src/utils/Loader';
import { KTSVG } from 'src/marketConnect/helpers';
import AppInfoAlert from 'src/utils/AppInfoAlert';

const AgentConnection = () => {
  const dispatch = useAppDispatch();

  // States for managing UI components
  const [showAgentModal, setShowAgentModal] = useState(false);
  const [showAvailableGPTModels, setShowAvailableGPTModels] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false); 
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);

  // State from Redux for fetched agents
  const { fetchedAgentsLoading, fetchedAgentsData } = useAppSelector(
    (state) => state.fetchConnectedAgents 
  );
  
  // Function to show the GPT models modal
  const onClickShowGPTModels = () => {
    setShowAvailableGPTModels(true);
  };


  const handleAgentAdded = async () => {
    console.log('Agent added');
    setShowAgentModal(false); // Close the modal
    dispatch(fetchConnectedAgentAsync()); // Fetch the updated list of agents
    toast.success('Agent added successfully!');
  };

  // Function to delete an agent
  const deleteAgent = async (agentId) => {
    console.log('Deleting agent', agentId);
    await dispatch(deleteAgentAsync({ id: agentId }));
    toast.success('Agent deleted successfully!');
    setShowAlertModal(false); // Close the alert modal
    dispatch(fetchConnectedAgentAsync()); // Fetch the updated list of agents
  };

  // Fetch agents on component mount
  useEffect(() => {
    dispatch(fetchConnectedAgentAsync());
  }, [dispatch]);

  if(fetchedAgentsLoading) return <Loader/>

  return (
    <>
      <div className='p-4 pt-0 rounded'>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-light-info' onClick={onClickShowGPTModels}>
            Add New Agent
          </button>
        </div>
      </div>
      <div className='py-2 pt-0'>
        {fetchedAgentsData?.length > 0 ? (
          fetchedAgentsData?.map((agent: any) => {
            return (
              <ConnectAgentStrip
                key={agent.id}
                agent={agent}
                onDelete={() => deleteAgent(agent.id)}
                // openSettingsModal={() => onClickSetting(platform)}
              />
            )
          })
        ) : (
          <div>
            <AppInfoAlert
             title='No agents founds yet'
             description={
              <div>
                <p>Add the agents from above button</p>
              </div>
             }
            />
          </div>
        )}
      </div>
      <AlertModal
        title={`Are you sure?`}
        subtitle={ <p> You are going to delete <b></b> you won't be able to revert this! </p> }
        isVisible={showAlertModal}
        closeModal={() => setShowAlertModal(false)}
        onConfirm={() => deleteAgent(selectedAgentId)}
      />
      <AvailableGPTModels
        isVisible={showAvailableGPTModels}
        closeModal={() => setShowAvailableGPTModels(false)}
        onGPTModelSelected={handleAgentAdded} // Assume this function also sets the selected agent
      />
      {/* Implement a component or method to list agents similar to ConnectPlatformStrip for platforms */}
    </>
  );
};

export default AgentConnection;


// import { useState } from 'react';
// import AlertModal from 'src/utils/modals/AlertModal'; 
// import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
// import { toast } from 'react-toastify';
// import AddNewAgent from './components/AddNewAgent'; 
// import AvailableGPTModels from './components/AvailableGPTModels';

// const AgentConnection = () => {
//   const dispatch = useAppDispatch();

//   const [showAgentModal, setShowAgentModal] = useState<boolean>(false);
//   const [showAvailableGPTModels, setShowAvailableGPTModels] = useState<boolean>(false)
//   const [showAlertModal, setShowAlertModal] = useState<boolean>(false); 
//   const [selectedAgent, setSelectedAgent] = useState<any>(null)
//   const [selectedAgentId, setSelectedAgentId] = useState(null);

//   const { fetchedConnectedPlatformLoading, fetchedConnectedPlatformData } = useAppSelector(
//     (state) => state.fetchConnectedPlatform)
  
//   const onClickShowGPTModels = () => {
//     setShowAvailableGPTModels(true);
//   };

//   const handleAgentAdded = async () => {
//     // Implement logic after an agent is added, such as fetching updated list of agents
//     console.log('Agent added');
//     setShowAgentModal(false); // Hide the modal after adding
//     // Potentially dispatch an action to fetch updated agents list
//     // dispatch(fetchAgentsAsync());
//     toast.success('Agent added successfully!');
//   };

//   // You might not need this delete function, but it's here if you have similar functionality for agents
//   const deleteAgent = async (agentId: any) => {
//     // Assuming there's functionality to delete an agent
//     console.log('Deleting agent', agentId);
//     // await dispatch(deleteAgentAsync({ id: agentId }));
//     toast.success('Agent deleted successfully!');
//     setShowAlertModal(false); // Assuming there's a scenario that might trigger an alert modal for deletion
//     // Refetch or update state as needed
//   };

//   return (
//     <>
//       <div id='test1' className='p-4 pt-0 rounded'>
//         <div className='d-flex justify-content-end'>
//           <button className='btn btn-light-info' onClick={onClickShowGPTModels}>
//             Add New Agent
//           </button>
//         </div>
//       </div>
//       <AlertModal
//         isVisible={showAlertModal}
//         closeModal={() => setShowAlertModal(false)}
//         // Assuming you have similar functionality
//         onConfirm={() => deleteAgent('some-agent-id')}
//       />
//       <AvailableGPTModels
//         isVisible={showAvailableGPTModels}
//         closeModal={() => setShowAvailableGPTModels(false)}
//         onGPTModelSelected={handleAgentAdded}
//       />
//     </>
//   );
// };

// export default AgentConnection;







