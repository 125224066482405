import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { accessSessionTokenKeyName } from 'src/utils/constants';



type REQUEST_METHODS = 'GET' | 'POST' | 'PUT' | 'DELETE';
type ENDPOINT = string;
type PARAMS = Object;

const getTokenSync = () => {
  return sessionStorage.getItem(accessSessionTokenKeyName);
};

// Create a base Axios instance
const baseAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add a response interceptor
baseAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 500) {
      toast.error('Something went wrong with our server. Please try again later.');
    } else {
      // toast.error('An error occurred. Please try again.'); // this toaster is commented coz it is always showing in every api failed while we are showing specific reason
    }
    return Promise.reject(error);
  }
);

const AxiosClient = async (
  method: REQUEST_METHODS,
  endPoint: ENDPOINT,
  payload: any,
  toolkit: any,
  params: PARAMS,
) => {
  // Adjust headers based on the endpoint
  console.log("endpoint", endPoint)
  const headers = endPoint === 'register/' || endPoint === 'forgot-password/' || endPoint === 'login/'
    ? {}
    : { Authorization: `Bearer ${getTokenSync()}` };
  console.log("headers", headers);
  try {
    const response = await baseAxios({
      url: endPoint,
      method: method,
      data: payload,
      params: { ...params },
      headers,
    });

    return toolkit.fulfillWithValue(response.data);
  } catch (e) {
    console.error("error:", e);
    return toolkit.rejectWithValue(e);
    // if (axios.isAxiosError(e)) {
    //   // More detailed error handling based on response status or error messages
    //   let errorMessage = 'An unexpected error occurred'; // Default error message
    //   if (e.response) {
    //     const { status, data } = e.response;
    //     if (status === 404) {
    //       errorMessage = 'Resource not found'; // Specific message for 404 errors
    //     } else if (data && data.message) {
    //       errorMessage = data.message; // Server-provided error message, if available
    //     } else if (status === 500) {
    //       errorMessage = 'Server error. Please try again later.'; // Custom message for 500 errors
    //     }
    //   }
    //   return toolkit.rejectWithValue(errorMessage);
    // } else {
    //   // Generic error handler for non-Axios errors
    //   return toolkit.rejectWithValue('An unexpected error occurred');
    // }
  }
};

export default AxiosClient;
