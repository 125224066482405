// platformSlice.js

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchPlatformsAsync } from "../actions/platformActions";
import { Platform } from "src/interfaces/Platform.interface";

type platformPayload={
    platformData:Platform[]| []
    platformLoading:boolean

}


const initialState :platformPayload= {
  platformLoading:false,
  platformData : []
};

const platformSlice = createSlice({
  name: "platformSlice",
  initialState,
   extraReducers: (builder) => {
   
    builder.addMatcher(isAnyOf(fetchPlatformsAsync.pending), (state,action)=>{
        state.platformLoading=true; 
      })
      builder.addMatcher(isAnyOf(fetchPlatformsAsync.fulfilled), (state,action)=>{
      
        state.platformLoading=false; 
        state.platformData = action?.payload?.data;
      })
      builder.addMatcher(isAnyOf(fetchPlatformsAsync.rejected), (state)=>{
        state.platformLoading=true; 
      })
      
  },
  reducers: {
    setPlatformData :()=> initialState

      
  }  
});


export const {setPlatformData} = platformSlice.actions
export default platformSlice.reducer;
