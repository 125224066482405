import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { PlatformDataType } from 'src/interfaces/Platform.interface'
import { retrivePlatformDataAsync } from 'src/redux/actions/platformActions'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'

type ConnectedPlatformSettingModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  onSaveSettings: (settings: any) => void
  selectedPlatform: PlatformDataType
}

const ConnectedPlatformSettingModal: FC<ConnectedPlatformSettingModalPropsType> = ({
  isVisible,
  closeModal,
  selectedPlatform,
  onSaveSettings,
}) => {
  const [showOrderSync, setShowOrderSync] = useState<boolean>(false)
  const [showProductSync, setShowProductSync] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { retrivedPlatformData, retrivedPlatformLoading } = useAppSelector(state => state.retrivePlatformData)

  useEffect(() => {
    if (isVisible) {
      dispatch(retrivePlatformDataAsync({ id: selectedPlatform.id }))
    }
  }, [isVisible, selectedPlatform, dispatch])

  useEffect(() => {
    if (retrivedPlatformData) {
      setShowOrderSync(!!retrivedPlatformData.is_order_sync_enabled)
      setShowProductSync(!!retrivedPlatformData.is_product_sync_enables)
    }
  }, [retrivedPlatformData])

  const createPayload = (): any => {
    return {
      platformId: selectedPlatform?.id,
      order_setting: {
        isOrderSyncEnabled: showOrderSync
      },
      product_setting: {
        isProductSyncEnabled: showProductSync
      },
    }
  }

  const handleSaveAllSettings = () => {
    const allSettings = createPayload()
    onSaveSettings(allSettings)
    closeModal()
  }

  return (
    <>
      <Modal fullscreen show={isVisible} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>Platform Setting</Modal.Title>
          <div className='d-inline-flex'>
            <Button size='sm' className='mx-2' onClick={closeModal}>
              Close
            </Button>
            <Button size='sm' variant='success' onClick={handleSaveAllSettings}>
              Save Changes
            </Button>
          </div>
        </Modal.Header>
        {retrivedPlatformLoading ? <Loader /> : 
        <Modal.Body>
          <div className='card m-2 p-4 shadow'>
            <div>
              <p className='h3'>Platform Connection</p>
            </div>
            <div className='border-bottom my-2 border-2'></div>
            <div className='p-4'>
              <div className='my-2 d-flex flex-row align-items-center'>
                <p className='h4 mb-0'>Name</p>
                <span className='badge badge-info ms-4'>
                  <h4 className='mb-0 text-white'>{retrivedPlatformData?.platform?.display_name}</h4>
                </span>
              </div>
              <div className='my-2 d-flex flex-row align-items-center'>
                <p className='h4 mb-0'>Status</p>
                <span className={`badge ms-4 ${retrivedPlatformData?.platform?.isConnected ? 'badge-success' : 'badge-danger'}`}>
                  {retrivedPlatformData?.platform?.isConnected ? 'Connected' : 'Not Connected'}
                </span>
              </div>
            </div>
          </div>

          <div className='card m-2 p-4 shadow'>
            <div>
              <p className='h3'>Orders</p>
            </div>
            <div className='border-bottom my-2 border-2'></div>
            <div className='p-4'>
              <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                <label className='form-check-label text-dark h4' htmlFor='orderSync'>
                  Sync Orders
                </label>
                <input
                  className='ms-8 form-check-input h-20px w-40px'
                  type='checkbox'
                  id='orderSync'
                  checked={showOrderSync}
                  onChange={() => setShowOrderSync(!showOrderSync)}
                />
              </div>
            </div>
          </div>
          <div className='card m-2 p-4 shadow'>
            <div>
              <p className='h3'>Product</p>
            </div>
            <div className='border-bottom my-2 border-2'></div>
            <div className='p-4'>
              <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                <label className='form-check-label text-dark h4' htmlFor='productSync'>
                  Sync Product
                </label>
                <input
                  className='ms-8 form-check-input h-20px w-40px'
                  type='checkbox'
                  id='productSync'
                  checked={showProductSync}
                  onChange={() => setShowProductSync(!showProductSync)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>}
      </Modal>
    </>
  )
}

export default ConnectedPlatformSettingModal
