import React, { FC } from 'react';
import { UserAgentSetting } from 'src/interfaces/Platform.interface';
import { KTSVG } from 'src/marketConnect/helpers';

type ConnectAgentStripProps = {
    agent: UserAgentSetting;
    onDelete: () => void;
    // openSettingsModal: () => void;
}

const ConnectAgentStrip: FC<ConnectAgentStripProps> = ({ agent, onDelete}) => {
    return (
        <div className='card shadow p-2 my-2'>
            <div className='d-flex flex-md-row justify-content-between p-5 border border-light-primary rounded'>
                <div className='align-self-center'>
                    <h4 className='title mb-0'>{agent.model_name}</h4> {/* Assuming the agent has a 'name' field */}
                </div>
                <div className='d-md-inline-flex'>
                    {/* Uncomment or modify based on actual functionality */}
                    {/* <button className='btn p-2' onClick={openSettingsModal}>
                        <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-2hx" />
                    </button> */}
                    <button className='btn p-2' onClick={onDelete}>
                        <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConnectAgentStrip;
