import { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import { createPlatformAsync } from 'src/redux/actions/platformActions'
import useAppToast from 'src/hooks/useAppToast'
import { jwtDecode } from 'jwt-decode'

type AddNewPlatformPropsType = {
    isVisible: boolean
    closeModal: () => void
    onPlatformAdded: () => void;

}

const initialValues = {
    platformName: '',
    identifier: '',
    shopToken: '',
}

const creationSchemaPlatform = Yup.object().shape({

    platformName: Yup.string()
        .min(2, 'Minimum 2 characters')
        .max(50, 'Maximum 50 characters')
        .matches(/^[a-zA-Z0-9- ]*$/, 'Only alphanumeric characters, spaces, and hyphens are allowed')
        .required('Platform name is required'),

    // identifier: Yup.string()
    //     .min(3, 'Minimum 3 characters')
    //     .matches(
    //         // /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com$/,
    //         /([a-z0-9]+[.])*shopify[.]com/,
    //         'Enter a valid Shopify domain (e.g., store-name.myshopify.com)'
    //     )
    //     .required('Domain name is required'),

    shopToken: Yup.string()
        .min(10, 'Token seems too short')
        .matches(/^[a-zA-Z0-9_\-\/]*$/, 'Token must be alphanumeric')
        .required('Access token is required'),

})

const ConnectNewPlatform: FC<AddNewPlatformPropsType> = ({ isVisible, closeModal, onPlatformAdded }) => {
    interface CustomJwtPayload {
        user_id?: number
    }

    const token = sessionStorage.getItem('access_token') || '';
    const decoded_token = jwtDecode<CustomJwtPayload>(token);
    const decoded_user_id = decoded_token?.user_id

    const [loading, setLoading] = useState(false)
    const { successToast, errorToast } = useAppToast()
    const dispatch = useAppDispatch()
    const { } = useAppSelector((state) => state)

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchemaPlatform,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                dispatch(
                    createPlatformAsync({
                        platformName: values.platformName,
                        // identifier: values.identifier,
                        shopToken: values.shopToken,
                        user_id: decoded_user_id as number,
                        // user_type: 'USER',
                        // is_superuser: false,
                        // is_staff: false,
                    })
                )
                    .then((response) => {
                        if (response && response.payload?.status === "success") {
                            successToast(response.payload?.status);
                            setSubmitting(false)
                            closeModal();
                            onPlatformAdded();

                        } else {
                            errorToast(response.payload?.status);
                            setSubmitting(false)
                        }

                    }).finally(() => {
                        setLoading(false);
                        setSubmitting(false);
                    });
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        },
    })

    return (
        <>
            <Modal size={'lg'} backdrop='static' centered show={isVisible} onHide={closeModal}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Add new platform</Modal.Title>
                        <div className='d-inline-flex'>
                            <Button size='sm' className='mx-2' disabled={loading} onClick={closeModal}>
                                Close
                            </Button>
                            <Button size='sm' variant='success' disabled={loading} type='submit'>
                                { loading ? 'Saving...' : 'Save Changes'}
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className='p-2 d-flex mb-8 border rounded flex-row align-items-center'>
                        <p className='form-label mb-0'>Platform Name</p>
                        <div className='ms-8 d-flex flex-row justify-content-center align-items-center'>
                            <img
                                src='https://theme.zdassets.com/theme_assets/11606116/c271c0edf9baeb714db2ccef96cbd6d86a0a296f.png'
                                width={'30px'}
                                height={'30px'}
                            />
                            <p className='h4 mb-0 text-success ms-4'>Test Platform</p>
                        </div>
                    </div> */}

                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}

                        <div className='mb-8'>
                            <label className='form-label'>Platform name</label>
                            <input
                                type='text'
                                placeholder='Enter platform name'
                                autoComplete='off'
                                {...formik.getFieldProps('platformName')}
                                className={clsx(
                                    'form-control',
                                    'bg-transparent',
                                    {
                                        'is-invalid': formik.touched.platformName && formik.errors.platformName,
                                        'is-valid': formik.touched.platformName && !formik.errors.platformName,
                                    }
                                )}
                            />
                            {
                                formik.touched.platformName && formik.errors.platformName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.platformName}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {/* <div className='mb-8'>
                            <label className='form-label'>Store URL</label>
                            <input
                                type='text'
                                placeholder='Enter domain name'
                                autoComplete='off'
                                {...formik.getFieldProps('identifier')}
                                className={clsx(
                                    'form-control', // Keep the static class here
                                    'bg-transparent', // Moved this from the second className definition
                                    {
                                        'is-invalid': formik.touched.identifier && formik.errors.identifier,
                                        'is-valid': formik.touched.identifier && !formik.errors.identifier,
                                    }
                                )}
                            />
                            {
                                formik.touched.identifier && formik.errors.identifier && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.identifier}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div> */}
                        <div className='mb-8'>
                            <label className='form-label'>Access Token</label>
                            <input
                                type='text'
                                placeholder='Enter access token'
                                autoComplete='off'
                                {...formik.getFieldProps('shopToken')}
                                className={clsx(
                                    'form-control', // Keep the static class here
                                    'bg-transparent', // Moved this from the second className definition
                                    {
                                        'is-invalid': formik.touched.shopToken && formik.errors.shopToken,
                                        'is-valid': formik.touched.shopToken && !formik.errors.shopToken,
                                    }
                                )}
                            />
                            {
                                formik.touched.shopToken && formik.errors.shopToken && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.shopToken}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                    </Modal.Body>
                </form>
            </Modal>
        </>
    )
}

export default ConnectNewPlatform
