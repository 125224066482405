import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosClient from "../AxiosClient";
import { PlatformSettingsDataType, connectedPlatformPayload } from "src/interfaces/Platform.interface";



export const fetchPlatformsAsync = createAsyncThunk(
  "platform/fetchPlatforms",
  async function (payload, toolkit) {
    return await AxiosClient("GET", "api/available-platform", [], toolkit, [])
  }
);

export const createPlatformAsync = createAsyncThunk(
  "platform/createNewPlatform",
  async function (payload: {
    platformName: string,
    // identifier: string,
    shopToken: string
    user_id: number
  },
    toolkit) {
    return await AxiosClient("POST", "api/create-platform/", payload, toolkit, [])
  }
);

export const createDatabaseFormAsync = createAsyncThunk(
  "database/createNewDatabase",
  async function (payload: {
    host: string,
    name: string,
    user: string,
    password: string
    user_id: number
  },
    toolkit) {
    return await AxiosClient("POST", "api/create-user-db-setting/", payload, toolkit, [])
  }
);

// Async action to connect a new platform
export const connectPlatformAsync = createAsyncThunk(
  "platform/connectPlatform",
  async function (payload: connectedPlatformPayload, toolkit) {
    return await AxiosClient("POST", "api/platform", payload, toolkit, []);
  }
);

export const fetchConnectedPlatformAsync = createAsyncThunk(
  "platform/fetchConnectedPlatform",
  async function (payload: { user_id: number }, toolkit) {
    const url = `api/get-platforms/${payload.user_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

export const fetchUserDbAsync = createAsyncThunk(
  "platform/fetchUserDb",
  async function (payload: { user_id: number }, toolkit) {
    const url = `api/get-user-db-setting/${payload.user_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

export const fetchConnectedDatabaseAsync = createAsyncThunk(
  "platform/fetchConnectedDatabase",
  async function (payload: { user_id: number }, toolkit) {
    const url = `api/get-user-db-setting/${payload.user_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

export const deletePlatformAsync = createAsyncThunk(
  "platform/deletePlatform",
  async function (payload: { id: number }, toolkit) {
    const url = `api/delete-platforms/${payload.id}`
    return await AxiosClient("DELETE", url, [], toolkit, [])
  }

);

export const deleteDatabaseAsync = createAsyncThunk(
  "platform/deleteDatabase",
  async function (payload: { id: number }, toolkit) {
    const url = `api/delete-user-db-setting/${payload.id}`
    return await AxiosClient("DELETE", url, [], toolkit, [])
  }

);

export const retrivePlatformDataAsync = createAsyncThunk(
  "platform/retrivePlatformData",
  async function (payload: { id: number }, toolkit) {
    const url = `/api/get-platform-sync-settings/${payload.id}`;
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

// export const retrivePlatformSettingAsync = createAsyncThunk(
//   "platform/retrivePlatformData",
//   async function (payload: { id: number }, toolkit) {
//     const url = `/api/get-platform-sync-settings/${payload.id}`;
//     return await AxiosClient("GET", url, [], toolkit, [])
//   }
// );

export const createPlatformSettingAsync = createAsyncThunk(
  "platform/createPlatformSetting",
  async function (payload: PlatformSettingsDataType, toolkit) {
    const url = `/api/create-platform-sync-settings/${payload.platformId}/`;
    delete payload.platformId;
    return await AxiosClient("POST", url, payload, toolkit, [])
  }
);

export const fetchAvailablePlatformsAsync = createAsyncThunk(
  "platform/fetchAvailablePlatforms",
  async function (payload, toolkit) {
    const url = `api/get-available-platforms/`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

export const askQueryAsync = createAsyncThunk(
  "agent/askQuery",
  async function (payload: {userQuery: string}, toolkit) {
    return await AxiosClient("POST", "api/ask-question/", payload, toolkit, []);
  }
);

export const getPlatformOrderData = createAsyncThunk(
  "platform/orderData",
  async function (payload: { selectedPlatformId: number, params:any }, toolkit) {
    return await AxiosClient("GET", `api/get-platform-order-data/${payload.selectedPlatformId}`, payload, toolkit, payload.params );
  }
);

export const getPlatformProductData = createAsyncThunk(
  "platform/orderData",
  async function (payload: { selectedPlatformId: number }, toolkit) {
    return await AxiosClient("GET", `api/get-platform-product-data/${payload.selectedPlatformId}`, payload, toolkit, []);
  }
);


export const getInventoriesAsync = createAsyncThunk(
  "platform/inventoryData",
  async function (params: { selectedPlatformId: number, otherParams:any }, toolkit) {
    return await AxiosClient("GET", `api/get-platform-product-data/${params.selectedPlatformId}`, [], toolkit, params.otherParams );
  }
);



