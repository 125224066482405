import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { fetchAvailablePlatformsAsync, fetchConnectedPlatformAsync } from 'src/redux/actions/platformActions';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import ConnectNewPlatform from './ConnectNewPlatform'
import { jwtDecode } from 'jwt-decode'
import Loader from 'src/utils/Loader';
interface AvailablePlatformsProps {
  isVisible: boolean;
  closeModal: () => void;
  onPlatformAdded: () => void;
}

const AvailablePlatforms: React.FC<AvailablePlatformsProps> = ({
  isVisible,
  closeModal,
  onPlatformAdded,
}) => {

  interface CustomJwtPayload {
    user_id?: number;
  }
  const dispatch = useAppDispatch();

  const { loading, error, data: platforms } = useAppSelector((state) => state.availablePlatforms);

  const token = sessionStorage.getItem('access_token') || '';
  const decoded_token = jwtDecode<CustomJwtPayload>(token);
  const user_id = decoded_token?.user_id

  const [showAddPlatformModal, setShowAddPlatformModal] = useState<boolean>(false)
  const onClickAddNewPlatform = () => {
    setShowAddPlatformModal(true)
  }

  const handlePlatformAdded = () => {
    if (typeof user_id !== 'undefined') {
      dispatch(fetchConnectedPlatformAsync({ user_id }));
    } else {
      console.error('user_id is undefined');
    }
  };

  useEffect(() => {
    if (isVisible) {
      dispatch(fetchAvailablePlatformsAsync())
        .unwrap()
        .then(() => {
          // toast.success('Platforms fetched successfully');
          // onPlatformAdded(); This might not be needed here unless you perform some action post-fetch
        })
        .catch((error) => {
          console.error('Failed to fetch platforms:', error);
          toast.error('Failed to fetch platforms');
        });
    }
  }, [isVisible, dispatch]);

  if (!isVisible) return null;

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>{error}</div>;
  const imageStyle = {
    marginRight: "20px", 
    marginTop: "25px", 
    maxHeight: "80px", 
    minHeight: "80px", 
    width: "90px"
  };
  return (
    <Modal show={isVisible} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Available Platforms</Modal.Title>
      </Modal.Header>
      <Modal.Body>
    { loading ? <Loader/> : 
      <div className="container py-4">
          <div className="row">
            {platforms && platforms.length > 0 ? platforms.map(platform => (
              <div className="col-md-4 mb-4" key={platform.id}>
                <div className="shadow py-4 px-2 rounded ">
                  <img loading='lazy' src={platform.logo_ref} className="card-img-top mx-auto d-block" alt={platform.name} />
                  <div className="card-body text-center">
                    <h5 className="card-title">{platform.name}</h5>
                    <p className="card-text">{platform.display_name}</p>
                    <Button className="btn btn-sm btn-primary" onClick={onClickAddNewPlatform}>Connect</Button>
                  </div>
                </div>
              </div>
            )) : <p>No available platforms to display.</p>}
          </div>
        </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Close</Button>
      </Modal.Footer>
      {/* <ToastContainer /> */}
      <ConnectNewPlatform
        isVisible={showAddPlatformModal}
        closeModal={() => setShowAddPlatformModal(false)}
        onPlatformAdded={handlePlatformAdded}
      />
    </Modal>
    
  );
};

export default AvailablePlatforms;
