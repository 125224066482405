import React, {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getInventoriesAsync, getPlatformOrderData} from 'src/redux/actions/platformActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Select from 'react-select'
import AppDateFilter from 'src/utils/AppDateFilter'
import moment from 'moment'
import DataTable from 'react-data-table-component'

const Inventories: FC = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const {inventories, totalInventories, inventoryLoader} = useAppSelector((state) => state.platformOrderData)
  const [paginationData, setPaginationData] = useState({
    page_num: 1,
    page_limit: 10,
    from_date: '',
    to_date: '',
    q: '',
    sync_status: '',
    sort_direction: '', // DES for descending
    sort_by: '',
  })

  const columns = [
    {
      name: 'Product ID',
      selector: (row) => row.api_product_id,
      sortable: true,
    },
    {
      name: 'Variant ID',
      selector: (row) => row.api_variant_id,
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: 'SKU',
      selector: (row) => row.sku,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity,
      //   sortable: true,
      center: true,
       
    },
    {
      name: 'product updated at',
      selector: (row) => row.api_updated_at,
      sortable: true,
      cell: (row) =>
        row?.api_updated_at ? moment.utc(new Date(row.api_updated_at)).format('DD-MM-YY') : '',
    },
  ]

  useEffect(() => {
    console.log('params', params)
    if (params?.platformId) {
      dispatch(
        getInventoriesAsync({
          selectedPlatformId: Number(params?.platformId),
          otherParams: paginationData,
        })
      )
    }
  }, [paginationData])

  return (
    <div>
      <div className='my-2'  >
        <Link to={'/listing-channels'} className='btn btn-sm btn-outline btn-outline-primary' >
          <i className="bi bi-arrow-left-circle-fill fs-2"></i> Go Back
        </Link>
      </div>
      <div className='card p-4 bg-white ' style={{height: '76vh'}}>
        <p className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
          Inventories
        </p>
        <div className='card-header align-items-center gap-2 gap-md-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-4'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>{' '}
              <input
                type='text'
                data-kt-ecommerce-product-filter='search'
                className='form-control form-control-solid form-control-sm w-250px ps-12'
                placeholder='Search Inventory'
                value={paginationData.q}
                onChange={(ev) => {
                  setPaginationData((pre) => ({...pre, q: ev.target.value}))
                }}
              />
            </div>
          </div>
          <div className='card-toolbar flex-row-fluid justify-content-end gap-2 gap-md-5'>
            <div className='w-100 w-md-50 mw-200px '>
              <button
                className='form-select form-select-solid form-select-sm'
                onClick={() => setShowDatePicker(true)}
              >
                {paginationData.from_date || paginationData.to_date
                  ? moment(paginationData.from_date).format('DD/MMM/YY') +
                    ' - ' +
                    moment(paginationData.to_date).format('DD/MMM/YY')
                  : 'Pick Date Range'}
              </button>
            </div>
            {/* <div className='w-50 mw-150px'>
                <Select
                placeholder='Status'
                options={[
                    {
                        label:"Pending", value:"PENDING"
                    },
                    {
                        label:"Fulfilled", value:"FULFILLED"
                    }
                ]}
                isClearable={false}
                classNames={{
                    container: ({ className  }) => className = 'border-0 bg-light-secondary  rounded',
                    control: ({ className  }) => className = 'border-0 bg-light-secondary rounded ',
                    valueContainer: ({ className  }) => className = 'bg-light-secondary rounded fw-5 fw-bold text-light-secondary',
                    indicatorSeparator:({className})=> className = 'd-none',
                    dropdownIndicator:({className})=> className = 'bg-light-secondary rounded ',                  
                }}
                />
                </div> */}
            <button
              className='btn btn-info btn-sm'
              onClick={() => {
                setPaginationData({
                  page_num: 1,
                  page_limit: 10,
                  from_date: '',
                  to_date: '',
                  q: '',
                  sync_status: '',
                  sort_direction: '', // DES for descending
                  sort_by: '',
                })
              }}
            >
              Reset Filters
            </button>
          </div>
        </div>
        <div className='card-body'>
          <DataTable
            columns={columns}
            progressPending={inventoryLoader}
            fixedHeader
            fixedHeaderScrollHeight='100%'
            data={inventories}
            pagination
            paginationServer
            paginationPerPage={10}
            paginationTotalRows={totalInventories}
            onChangePage={(pageNum)=>{
              setPaginationData((pre)=>{
                return {...pre, page_num: pageNum}
              })
            }}
            onChangeRowsPerPage={(currentRowPerPage)=>{
              setPaginationData((pre)=>{
                return {...pre, page_limit: currentRowPerPage}
              })
            }}
            customStyles={{
              table: {
                style: {height: '50vh'},
              },
            }}
          />
        </div>
      </div>
      <AppDateFilter
        isVisible={showDatePicker}
        handleClose={() => setShowDatePicker(false)}
        onPickRange={(dateRange) => {
          setPaginationData((pre) => ({
            ...pre,
            from_date: dateRange.startDate,
            to_date: dateRange.endDate,
          }))
        }}
      />
    </div>
  )
}

export default Inventories

const OrderStatus: FC<{status: string}> = ({status}) => {
  switch (status) {
    case 'awaiting_payment':
      return (
        <span className='badge badge-light-warning w-100 text-center py-3 w-120px justify-content-center '>
          Waiting For Payment
        </span>
      )
    case 'awaiting_stock':
      return (
        <span className='badge badge-info w-100 text-center py-3 w-120px justify-content-center '>
          Waiting For Stock
        </span>
      )
    case 'awaiting_fulfillment':
      return (
        <span className='badge badge-light-success w-100 text-center py-3 w-120px justify-content-center '>
          Waiting For Fulfillment
        </span>
      )
    case 'shipped':
      return (
        <span className='badge badge-warning w-100 text-center py-3 w-120px justify-content-center '>
          Shipped
        </span>
      )
    case 'on_hold':
      return (
        <span className='badge badge-primary w-100 text-center py-3 w-120px justify-content-center '>
          On Hold
        </span>
      )
    case 'cancelled':
      return (
        <span className='badge badge-danger w-100 text-center py-3 w-120px justify-content-center '>
          Cancelled
        </span>
      )
    case 'refunded':
      return (
        <span className='badge badge-success w-100 text-center py-3 w-120px justify-content-center '>
          Refunded
        </span>
      )

    default:
      return <span></span>
  }
}
